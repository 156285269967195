<template>
  <div>
    <page-header :title="`Dashboard`">
      <template v-slot:breadcrumbs>
        <li>Dashboard</li>
      </template>

      <template v-slot:actions>
        <div></div>
      </template>
    </page-header>

    <div class="row mt-5">
      <div class="col-3">
        <div class="card card-raised border-start border-success border-2 widget">
          <div class="card-body px-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="">
                <div class="number">{{ clients }}</div>
                <div class="card-text">Ativos</div>
              </div>

              <div class="">
                <div class="number">{{ inactives }}</div>
                <div class="card-text">Inativos</div>
              </div>
              <div class="icon-circle bg-success text-white">
                <i class="la la-users" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="card card-raised border-start border-secondary border-2 widget">
          <div class="card-body px-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="me-2">
                <div class="number">{{ condos }}</div>
                <div class="card-text">Condomínios</div>
              </div>
              <div class="icon-circle bg-secondary text-white">
                <i class="la la-city" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="card card-raised border-start border-primary border-2 widget">
          <div class="card-body px-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="me-2">
                <div class="number">{{ properties }}</div>
                <div class="card-text">Imóveis</div>
              </div>
              <div class="icon-circle bg-primary text-white">
                <i class="la la-home" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="card card-raised border-start border-warning border-2 widget">
          <div class="card-body px-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="me-2">
                <div class="number">{{ users }}</div>
                <div class="card-text">Usuários</div>
              </div>
              <div class="icon-circle bg-warning text-white">
                <i class="la la-user" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <churn-chart />

    <div class="bg-white mt-5">
      <div class="card">
        <div class="card-body p-4">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="card-description text-primary">Últimos clientes cadastrados</h6>
            <a href="#" @click.prevent="$router.push('/clientes')">Ver todos</a>
          </div>
          <div class="table-responsive mt-3">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center">Código</th>
                  <th>Nome</th>
                  <th class="text-center">Criado em</th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="company in lastCompanies" :key="company.id">
                  <td class="text-center">{{ company.external_id }}</td>
                  <td>{{ company.name }}</td>
                  <td class="text-center">{{ company.created_at }}</td>
                  <th class="text-center">
                    <div class="d-flex align-items-center justify-content-end">
                      <button class="btn btn-sm btn-primary" @click.prevent="$router.push(`/clientes/${company.id}`)"><i class="la la-eye" /></button>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import axios from "axios";
import ChurnChart from "@/components/Dashboard/Churn.vue";
export default {
  name: 'dashboard',
  props: {},
  components: {
    ChurnChart,
    PageHeader
  },
  async mounted() {
    await axios.get('/admin/dashboard').then(response => {
      this.properties = response.data.properties;
      this.condos = response.data.condos;
      this.clients = response.data.companies;
      this.inactives = response.data.companiesInactive;
      this.users = response.data.users;
      this.lastCompanies = response.data.lastCompanies;
    })
  },
  data() {
    return {
      properties: 0,
      condos: 0,
      clients: 0,
      users: 0,
      inactives: 0,
      lastCompanies: []
    }
  }
}
</script>
