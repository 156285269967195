<template>
  <div class="bg-white mt-5">
    <loader :loading="defaultLoading" />
    <div class="card" style="min-height: 380px">
      <div class="card-body p-4">
        <div class="d-flex align-items-center justify-content-between">
          <h6 class="card-description text-primary">Mapeamento de clientes</h6>
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <select class="form-select form-select-sm" v-model="year">
                <option :value="year" v-for="year in years" :key="`year-${year}`"> {{ year }}</option>
              </select>
            </div>

            <div class="ms-3">
              <button class="btn btn-sm btn-success" :disabled="loading" @click.stop="getData">Atualizar</button>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center align-items-center flex-column" v-if="loading" style="min-height: 300px">
          <div class="spinner-border text-primary" role="status">
          </div>
          <span class="loading-text">Carregando...</span>
        </div>

        <Bar
            id="churn-chart"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :plugins="plugins"
            v-else
        />
      </div>
    </div>

    <modal id="logs-modal" :custom-classes="['modal-dialog-centered', 'modal-lg', 'modal-dialog-scrollable']" :title="`Clientes baixados no mês ${clients.month}/${year}`">
      <template #body>
        <table class="table table-striped">
          <thead>
          <tr>
            <th class="text-center">Código</th>
            <th>Cliente</th>
            <th class="text-center">Cadastro</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="client in clients.data" :key="client.id">
            <td class="text-center">{{ client.external_id }}</td>
            <td>{{ client.name }}</td>
            <td class="text-center">{{ client.created_at }}</td>
          </tr>
          </tbody>
        </table>
      </template>

      <template #footer>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" ref="closeModal">Fechar</button>
      </template>
    </modal>

  </div>
</template>
<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineController } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from "axios";
import Loader from "@/components/shared/loader.vue";
import Modal from "@/components/shared/Modal.vue";
import {Modal as bsModal} from "bootstrap";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels, LineController)

export default {
  name: 'churn-chart',
  props: {},
  components: {
    Modal,
    Loader,
    Bar
  },
  data() {
    const year = new Date().getFullYear();
    return {
      year: year,
      loading: false,
      defaultLoading: false,
      clients: {
        month: '',
        data: []
      },
      chartData: {
        labels: [ 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembo', 'Outubro', 'Novembro', 'Dezembro' ],
        datasets: [
          {
            label: 'Clientes ativos',
            backgroundColor: '#6769f0',
            data: [],
            datalabels: {
              anchor: 'start',
              align: 'end',
              offset: 10,
              font: {
                weight: 'bold'
              }
            }
          },
          {
            label: 'Novos Clientes',
            backgroundColor: '#00d39b',
            data: [],
            datalabels: {
              anchor: 'start',
              align: 'end',
              offset: 10,
              font: {
                weight: 'bold'
              }
            }
          },
          {
            label: 'Clientes inativos',
            backgroundColor: '#f27474',
            data: [],
            datalabels: {
              anchor: 'start',
              align: 'end',
              offset: 10,
              font: {
                weight: 'bold'
              },
              pointRadius: 10,
              listeners: {
                click: (context) => {
                  if(context.dataset.data[context.dataIndex] === 0) return;
                  this.getClients(context)
                },
              }
            }
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
          yAxis:{
            suggestedMin: 0,
            suggestedMax: 170
          }
        }
      },
      plugins: ['datalabels']
    }
  },
  mounted() {
    this.getData();
  },
  computed: {
    years() {
      const currentYear = new Date().getFullYear()
      let yearList = [];
      let startYear = 2022;
      for(let i = startYear; i <= currentYear; i++){
        yearList.push(startYear++);
      }
      return yearList;
    }
  },
  methods: {
    async getData() {
      this.loading = true;
      await axios.get(`/admin/dashboard/churn-data?year=${this.year}`,).then(response => {

        response.data.data.forEach((item, index) => {
          this.chartData.datasets[0].data[index] = item.total_companies;
          this.chartData.datasets[1].data[index] = item.new_companies;
          this.chartData.datasets[2].data[index] = item.inactive;
        });

      }).catch(error => {

      }).finally(() => {
        this.loading = false;
      })
    },
    async getClients(evt) {
      this.clients = [];
      this.defaultLoading = true;
      await axios.get(`/admin/dashboard/churn-data/clients?year=${this.year}&month=${evt.dataIndex + 1}`,).then(response => {
        this.clients = response.data;
        const modal = bsModal.getOrCreateInstance(document.getElementById('logs-modal'));
        modal.show();
      }).catch(error => {

      }).finally(() => {
        this.defaultLoading = false;
      });
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
